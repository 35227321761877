import React from 'react';
import {graphql, Link, navigate} from 'gatsby';
import Nav from '../components/Nav';
import HeroSimple from '../components/HeroSimple';
import Contact from '../components/Contact';
import Footer from '../components/Footer';
import SEO from '../components/seo/SEO';

const Template = ({data, pageContext}) => {
    const {start} = pageContext;
    const title = data.markdownRemark.frontmatter.title;
    const excerpt = data.markdownRemark.frontmatter.excerpt;
    const html = data.markdownRemark.html;
    return (
        <>
            <SEO />
            <div className="gradient">
                <Nav />
                <HeroSimple
                    heading={title}
                    subText={"PRÉSENTATION DU COURS"}
                    subHeading={excerpt}
                    buttonText="Accéder au cours"
                    handleClick={() => {
                        navigate(start)
                    }}
                />
                
            </div>
            <div className="container mx-auto mb-24">
              <h2 id="courses" className="w-full my-2 text-5xl font-bold leading-tight text-center text-gray-800">Description du cours</h2>
              <div className="w-full mb-24">	
                  <div className="h-1 mx-auto gradient w-64 opacity-25 my-0 py-0 rounded-t"></div>
              </div>
              <div className="markdown-content px-8 lg:px-0" dangerouslySetInnerHTML={{__html: html}} />

              <div className="w-full flex items-center flex-row">
                <Link to={start} className="z-10 mx-auto hover:underline bg-white text-white font-bold rounded-full my-6 py-4 px-8 shadow-lg gradient no-underline hover:no-underline">Commencer le cours</Link>
              </div>
              
            </div>

            <Contact />
            <Footer image={data.footerImage.childImageSharp.fluid} />
            
        </>
    )
}

export const query = graphql`
    query($pathSlug: String!) {
        markdownRemark(frontmatter: {path: {eq: $pathSlug}}) {
            html,
            frontmatter {
                title,
                excerpt
            }
        },
        footerImage: file(relativePath: {eq: "Logo.png"}) {
            childImageSharp {
                fluid(quality: 100) {
                    ...GatsbyImageSharpFluid_withWebp
                }
            }
        }
    }
`

export default Template;